var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-edit-team",
        attrs: {
          id: "modal-edit-team",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.editTeam.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("modal-choose-team-name-and-description", {
                  attrs: {
                    teamName: _vm.teamName,
                    teamDescription: _vm.teamDescription
                  },
                  on: {
                    "update-fields": _vm.updateFields,
                    "enter-pressed": _vm.editTeam
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.cancelModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: _vm.isDisabled,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.editTeam }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("modals.editTeam.change")) + " ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }